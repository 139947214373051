.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* our work */
.work-item {
  position: relative;
  overflow: hidden;
  ;
}

.card-overlay {
  padding: 20px;
  position: absolute;
  background: rgb(0 0 0 / 78%);
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 350ms cubic-bezier(0, 0, 0.2, 1);
  display: flex;
  align-items: center;
}

.work-item:hover .card-overlay {
  top: 0;
}

.card-overlay-inner p {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 3rem;
}

.work-slider .disabled {
  display: none;
}

.work-slider button.owl-next {
  position: absolute;
  top: 70%;
  right: 5%;
  transform: translate(-5%, -70%);
}

.work-slider button.owl-prev {
  position: absolute;
  top: 70%;
  left: 5%;
  transform: translate(-5%, -70%);
}

.work-slider button img {
  width: 4rem;
}

.work-slider button.owl-prev img {
  transform: rotate(180deg);
}

.swiper-button-next,
.swiper-button-prev {
  background-image: url(./sliderimages/right-arrow.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next {
  right: 5%;
  width: 80px;
  height: 80px;
}

.swiper-button-prev {
  transform: rotate(180deg);
  width: 80px;
  height: 80px;
  left: 5%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.backpage span {
  color: var(--white-color);
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 6px;
  position: absolute;
  top: 20%;
  left: 0%;
  transform: translate(0%, -20%);
}

@media(max-width:991px) {
  .backpage span {
    top: 15%;
    left: 1%;
    transform: translate(-1%, -15%);
  }
}

@media (max-width: 767px) {
  .backpage span {
    top: 15%;
    transform: translate(-1%, -15%);
  }
  .swiper-button-next,.swiper-button-prev{
    width: 30px;
    height: 30px;
  }
}


.boxerr{
  width: 100%;
  height: 550px;
  background-size: cover !important;
  background-position: center !important;


}

.boxerrMobile{
  width: 100%;
  height: 250px;
  background-size: cover !important;
  background-position: center !important;


}
.fixer{

  position: fixed !important;
  transition: all 1s ease;

}
.Mobilefixer{
  width: 100%;
  height: 250px;
  position: fixed !important;
  float: none !important;
  transition: all 1s ease;
}
.expand{
  /*width: 100%;*/
  /*height: 100vh;*/
  margin: 0;
  z-index: 100000;
}
.cardoverlHide .card-overlay  {
  opacity:0;
}
/* .parent{
  width: 100%;
  overflow-x: scroll;
  
} */

.workLanding {
  
    width: 100%;
    height: 100vh;
    /* position: relative; */
    background: #000;
    
    background-size: cover;
    background-position: center;

}