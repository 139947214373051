

.snapItem {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 95%;
    min-height: 95%;

}


.screen-layer img {}


.page-image{
    background: url();
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100vh;
}
/* @media(max-width:767px){
    .page-content-right ,.page-content-left{
    max-width: 320px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    transform: translate(0 , -50%);
  }
} */

/* PR content text */
.pr-content{
    position: relative;
    overflow: hidden;
}

@media(min-width:992px){
    .screen-layer-left:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg, #000000f0 10%, #00000029 100%);
    }
    .screen-layer-right:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg, #00000029 10%, #000000f0 100%);
    }
    .page-content-left {
        position: absolute;
        top: 50%;
        left: 5%;
        text-align: left;
        transform: translate(-5% , -50%);
        max-width: 550px;
    }
    .page-content-right {
        position: absolute;
        top: 50%;
        text-align: right;
        right: 5%;
        transform: translate(-5% , -50%);
        max-width: 550px;
        right:0
    }
}