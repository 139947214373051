
body.nav-active {
    overflow-y: hidden;
}
.cursor-dot,
.cursor-dot-outline {
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 10000;
}

.cursor-dot {
    width: 40px;
    height: 40px;
    /*background-color: #dc5656;*/
}

.cursor-dot-outline {
    width: 40px;
    height: 40px;
    /*background-color: rgba(229, 128, 128, 0.5);*/
}

.links-container {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
}

.Mail-curser {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 10000;
}
