.play-icon {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    color: var(--white-color);
    font-size: 5rem;
    font-weight: 100;
}
video{
    z-index: 8;
}

/* .video-play{
    height: 90vh;
} */