header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.main-menu {
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 9999;
}
body.nav-active .main-menu {
  position: fixed;
  width: 100%;
}
.main-menu .navbar-brand img {
  z-index: 9999;
  position: relative;
  width: 135px;
}
.menu-icon {
  z-index: 999;
}


.menu-icon__line {
  height: 2px;
  width: 30px;
  display: block;
  background-color: #ffffff;
  margin-bottom: 4px;
  transition: transform 0.2s ease, background-color 0.5s ease;
}
.menu-icon__line-left {
  width: 15px;
  margin-top: 7px;
}
.menu-icon__line-right {
  width: 15px;
  float: right;
}

header .nav {
  position: fixed;
  z-index: 99;
  top: 0;
}
.nav:before, .nav:after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #00FFFF;
  z-index: -1;
  transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
  transform: translateY(0%) translateX(100%);
}
.nav:after {
  background: var(--black-color);
  transition-delay: 0s;
}
.nav:before {
  transition-delay: 0.1s;
}
.nav__content {
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  cursor: pointer;
  height: 100%;
  visibility: hidden;
}
.nav-active .nav__content {
  
  visibility: visible;
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
  transition-delay: 0.5s;
}
.hamburger-menu{
  position: relative;
  /* display: inline-block; */
  transition-delay: 0.8s;
  opacity: 0;
  transform: translate(0%, 100%);
  transition: opacity 0.2s ease, transform 0.3s ease;
}
.nav__list  .nav__list-item{
  display: block;
  position: relative;
  list-style-type: none;
  text-align: left;
  font-family: 'BigNoodleTitlingOblique';
  color: var(--black-color);
  font-size: 3.5rem;
  padding: 0.5rem 0;
}
.nav__list  .nav__list-item a{
  color: var(--black-color);
  display: inline;
  position: relative;
  overflow: hidden;
}
.nav__list  .nav__list-item a:after{
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: -5px;
  background: #000;
  height: 4px;
  transition-property: left right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.nav__list  .nav__list-item a:hover:after{
  right: 0;
}
.news-list .nav__list  .nav__list-item{
  display: inline-block;
    font-size: 1rem;
    position: relative;
    letter-spacing: 2px;
    padding-right: 2rem;
    font-family: 'Avenir LT Std';
    text-transform: uppercase;
    font-weight: 900;
}
.news-list .nav__list .nav__list-item:not(:last-child):after{
  content: '|';
    position: absolute;
    right: 1rem;
}
.news-list .nav__list .primary-button{
  font-size: 3.5rem;
  font-family: 'BigNoodleTitlingOblique';
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 3.5rem;
}
.news-list .nav__list  .primary-button:after{color: var(--black-color);font-weight: 100;margin-left: 1rem;}
.nav__list-item:before {
  content: "";
  position: absolute;
  background: #000000;
  width: 20px;
  height: 1px;
  top: 100%;
  transform: translate(0%, 0%);
  transition: all 0.3s ease;
  z-index: -1;
}
.nav__list-item:hover:before {
  width: 100%;
}
body.nav-active .menu-icon__line {
  background-color: #000;
  transform: translateX(0px) rotate(-45deg);
  background-color: var(--primary-color);
}
body.nav-active .menu-icon__line-left {
  transform: translateX(1px) rotate(45deg);
  background-color: var(--primary-color);
}
body.nav-active .menu-icon__line-right {
  transform: translateX(-2px) rotate(45deg);
  background-color: var(--primary-color);
}
/* body.nav-active .nav {
  visibility: visible;
} */
body.nav-active .nav:before, body.nav-active .nav:after {
  transform: translateX(0%) translateY(0%);
}
body.nav-active .nav:after {
  transition-delay: 0.1s;
}
body.nav-active .nav:before {
  transition-delay: 0s;
}
body.nav-active .hamburger-menu {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
}
body.nav-active .hamburger-menu {
  transition-delay: 0.5s;
  height: 100%;
  overflow-y: scroll;
}
body.nav-active .hamburger-menu::-webkit-scrollbar {
  width: 0em;
}
body .hamburger-menu {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s ease, transform 0.5s ease, color 0.3s ease;
  transition-delay: 0.2s;
}
.menu-contact ul li{
  list-style-type: none;
  font-size: 20px;
  padding: 0.4rem 0;
}
.menu-contact h6{font-size: 2.4rem;}
.menu-contact  .social-media li{
  display: inline-block;
}
.menu-contact .social-media li a{
  background: var(--primary-color);
    font-size: 1.6rem;
    margin-left: 0;
    margin-right: 1.5rem;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    border-radius: 50%;
    color: var(--black-color);
    padding: 4px 0;
}
@media(max-width:991px){
  body.nav-active .hamburger-menu {
    margin-top: 10rem;
  }

  .nav__content{
    overflow: hidden;
    overflow-y: scroll;
  }
}
@media(max-width:767px){
  body.nav-active .main-menu{
    background: var(--black-color);
  }
  .main-menu .navbar-brand img{
    width: 100px;
  }
  .main-menu .container{
    padding: 0;
  }
  body.nav-active .hamburger-menu {
    margin-top: 7rem;
    
  }
  body.nav-active .hamburger-menu ul,.menu-contact h6,.menu-contact form{
    padding: 0 1.5rem !important;
  }
  .menu-contact form input.form-control{
    width: 100% !important;
    border: none;
    color: var(--black-color) !important;
  }
  .menu-contact form input.form-control::placeholder{color: var(--black-color) !important;}
  .nav__list .nav__list-item,.news-list .nav__list .primary-button{
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .news-list {
    margin-top: 2rem;
  }
 
}
/* header ends */