/* Team */
.team-members {
    padding: 8rem 0;
    overflow-x: hidden;
  }
  .team-wrapper{
    position: relative;
  }
  .team-card-footer{
    margin-top: -160px;
  }
  .team-card{
    width: 100%;
    height: 43rem;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 125%;
    
  }
  .team-item.team-card,.team-card .card-header {
    border: 0 !important;
    border-radius: 0;
    height: 100%;
  }
  .leaders ul{
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .leaders ul li{
    display:inline-grid;
    max-width: 400px;
    flex-shrink: 0;
    margin-right: 10px;
    width: 400px;
    /* padding-bottom: 5rem; */
  }
  .leaders ul li:first-child{
    margin-left: 10%
  }
  .leaders ul li:last-child{
    margin-right: 10%
  }
  .team-card p{
    font-size: 16px;
    font-weight: 300;
  }
  /* .team-image {
    height: 270px;
  } */
  .team-card .card-body img{
    /* vertical-align: bottom ;
    height: 100%;
      object-fit: cover; */
      /* position: absolute;
      bottom: 0; */
  }
  .team-card .card-header{margin-bottom: -1px;}
  .team-card .card-header h6{font-size: 3rem;}
  .leaders ul::-webkit-scrollbar {
    background: var(--white-color);
    border-top: 5px solid var(--black-color);
    border-bottom: 4px solid var(--black-color);
   
  }
  
  .leaders ul::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 10px;
    height: 5px;
  }