:root{
  --primary-color:#00FFFF;
  --white-color:#ffffff;
  --black-color:#000000;
}
html {
  /*scroll-snap-type: y mandatory;*/

}
*{
  cursor: url(https://cms.powerleaguegaming.com/wp-content/uploads/2022/09/Normal-pointer-2.png) 12 12, auto !important;
}


.snapCenter {
  scroll-snap-align: start;
}

.snapNone {
  scroll-snap-align: none;
}

.snapStart {
  scroll-snap-align: start;
}
/**,html,body{*/
/*cursor: url(../../curser-pointer/Normal-pointer.png)17 18, auto;*/
/*transition: all 1s ease;*/

/*}*/
/* :not(button){
  cursor: url(../../curser-pointer/Contact-us.png)10 20, auto;
} */
/*button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {*/
/*cursor: pointer;*/
/*transition: all 1s ease;*/

/*}*/
.text-primary{color: var(--primary-color) !important;}
.bg-primary{background: var(--primary-color) !important;}

.text-white{color: var(--white-color) !important;}
.bg-white{background: var(--white-color) !important;}

.text-black{color: var(--black-color) !important;}
.bg-black{background: var(--black-color) !important;}

.bg-0{background: transparent;background-color: transparent;}
.Avenir{font-family: 'Avenir LT Std';}
.BigNoodleTitling{font-family: 'BigNoodleTitling';}

body{
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
  background-color: var(--black-color);
  color: var(--white-color);
  font-family: 'Avenir LT Std';
}
a{
  text-decoration: none;
  color: var(--white-color);
}
a:hover{color: var(--primary-color);}
.fw-700{font-weight: 700;}
.fw-900{font-weight: 900;}
h1,h2,h3,h4,h5,h6{font-family: 'BigNoodleTitlingOblique';font-weight: 300;}
h1{
  font-size: 7rem;
  line-height: 7rem;
}
h2{
  font-size: 5rem;
  line-height: 5rem;
}
h3{
  font-size: 4.5rem;
  line-height:4.5rem;
}
h4{
  font-size: 4rem;
  line-height: 4rem;
}
h5{
  font-size: 3.8rem;
  line-height: 3.8rem;
}
h6{
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.theam-button{
  background-color: var(--primary-color);
  color: var(--black-color);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 0.8rem 1rem 0.6rem;
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  border:0;
}
/* .stickey-button {
  position: fixed;
  bottom: 16%;
  z-index: 8;
  left: 0px;
  transform: scale(0.7);
  -webkit-transform-origin-x: 0;
} */
/* .stickey-whatsapp {
  position: fixed;
  bottom: 0%;
  z-index: 8;
  left: 100px;
  transform: scale(0.6);
  -webkit-transform-origin-x: 0;
    z-index: 999;
} */
.wp-icon {
  width: 70px;
  height: auto;
}
.sticky-items {
  position: fixed;
  bottom: 16%;
  z-index: 8;
  left: 0;
  transform: scale(0.7);
  -webkit-transform-origin-x: 0;
  display: flex;
  align-items: center;
}
.stickey-whatsapp {
 display: block;
}
.stickey-button a{
  color:black !important;
}
.theam-button:hover{
  color: var(--black-color);
}
.primary-button{
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 800;
  background: transparent;
  border: 0;
  padding:0.6rem 1rem;
  letter-spacing: 5px;
  position: relative;
  font-size: 16px;
  transition: all 0.5s ease-in-out;
}
.primary-button:after{
  content: '\f061';
  color: var(--primary-color);
  font-family: 'FontAwesome';
  transform: rotate(315deg);
  position: absolute;
  margin: -5px 0;
  transition: all 0.5s ease-in-out;
}
.primary-button:hover:after{
  margin: -5px 8px;
  transition: all 0.5s ease-in-out;
}
/* prograssbar on scroll page */
.progress-bar {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 8px;
  background: var(--primary-color);
  width: 0%;
}

/*.cursor {*/
/*width: 2rem;*/
/*height: 2rem;*/
/*!* background: url(../images/mouse-pointer.png); *!*/
/*background-repeat: no-repeat;*/
/*background-size: 100% 100%;*/
/*border-radius: 50%;*/
/*position: fixed;*/
/*transform: translate(-50%, -50%);*/
/*pointer-events: none;*/
/*transform-origin: 100% 100%;*/
/*z-index: 99;*/
/*}*/
.link-grow {
  background: #00ffff69;
  border: 1px solid var(--primary-color);
}

button.owl-prev img {
  transform: rotate(180deg);
}
.owl-nav button img {
  width: 4rem;
}
button.owl-prev {
  position: absolute;
  top: 70%;
  left: 5%;
  transform: translate(-5%, -70%);
}
button.owl-next {
  position: absolute;
  top: 70%;
  right: 5%;
  transform: translate(-5%, -70%);
}

/* animation */

.drop-in {
  animation: drop-in 800ms  ease 500ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
/* footer starts */
footer{
  text-transform: uppercase;
  font-size: 14px;
  padding: 3rem 0;
  font-weight: 800;
}
footer ul li{
  list-style-type: none;
  display: inline-block;
  letter-spacing: 3px;
  margin: 0 1rem;

}
.social-media li{margin:0;vertical-align: middle;}
.social-media li a {
  /* color: var(--black-color);
  background: var(--white-color);
  width: 33px;
  height: 33px;
  display: block;
  border-radius: 50%;
  padding: 3px 3px;
  font-size: 1.3rem; */
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 0.8rem;
}
/* Footer Ends */
/* HOME START */

.scroll-down {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.scroll-down .scroll-down-text{
  color:var(--white-color);
  text-decoration:none;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
}
.scroll-down .scroll-down-text i{
  font-size: 2rem;
}
/* .text-shadow{
  text-shadow: 1px 1px 5px var(--primary-color);
} */

/* video */
.video-section{
  padding: 5rem 0;
  height: 100vh;
}
.video-play .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  color: var(--white-color);
  font-size: 5rem;
  font-weight: 100;
}
/* PLG BRANDS */
.plg-brand{
  /* height: 100vh; */
  padding: 8rem 0;
}

/* our works */
.work-item{
  position: relative;
  overflow:hidden;;
}
.card-overlay{
  padding:20px;
  position:absolute;
  background:rgb(0 0 0 / 78%);
  left:0;
  top: 100%;
  width:100%; height:100%;
  z-index: 1;
  transition:all 350ms cubic-bezier(0, 0, 0.2, 1);
  display:flex;
  align-items:center;
}
.work-item:hover .card-overlay {
  top: 0;
  display: grid;
}
.card-overlay-inner p {
  color: var(--primary-color);
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: 3rem;
}
.disabled{display: none;}
button.owl-next {
  position: absolute;
  top: 70%;
  right: 5%;
  transform: translate(-5%, -70%);
}
button.owl-prev {
  position: absolute;
  top: 70%;
  left: 5%;
  transform: translate(-5%, -70%);
}
.work-slider button img{width: 4rem;}
button.owl-prev img{
  transform: rotate(180deg);
}

/* STUDIO */
.studios,.about-section,.our-works{
  /* height: 100vh; */
  padding: 8rem 0;
}
/* let's connect */
.home-contact{
  /* height: 100vh; */
  padding: 8rem 0;
}
.connect-tabs ul li button{
  text-transform: uppercase;
  opacity: 1;
  color: var(--white-color) !important;
  margin: 0 2.5rem;
  font-weight: 800;
}
.connect-tabs ul li button.active{
  background-color: transparent !important;
  color: var(--primary-color) !important;
}
.form-control{
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: 2px;
  border: 0;
  padding: 0.8rem 1rem;
  border-radius: 0;
}
.form-control::placeholder{
  color: var(--black-color)
}


/* Services page starts */
.services{overflow: hidden;padding: 8rem 0 8rem;}
.services-blocks  sup {
  font-size: 16px;
  font-weight: 800;
  line-height: 0;
  vertical-align: bottom;
  margin-right: 2.5rem;
  font-family: 'Avenir LT Std';
  letter-spacing: 3px;
  transition: all 0.5s ease-in-out;
}
.services-blocks h4{
  transition: all 0.5s ease-in-out;
  margin-left: 2.5rem;
  display: table;
  margin-bottom: 5.5rem;
  border-bottom:1px solid transparent;
}
.services-blocks:hover h4{
  color: var(--primary-color);
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid var(--primary-color);
}
.services-blocks:hover sup{
  color:var(--primary-color)
}
.services-blocks .services-content{
  position: absolute;
  top: 0;
  left: 100vw;
  display: block;
  width: 560px;
  opacity: 0;
  transition: left .3s cubic-bezier(1,.42,.57,.84) 0s,opacity .3s cubic-bezier(1,.42,.57,.84) 0s;
}
.services-blocks:hover .services-content{
  left:45vw;
  opacity:1;
  transition:left .6s cubic-bezier(1,.42,.57,.84) 0s,opacity .6s cubic-bezier(1,.42,.57,.84) 0s
}
/* Services Page Ends */


/* STUDIO SCREEN STARTS */
.page-banner{
  margin: 0rem 0 3rem;
}


/*!* Team *!*/
/*.team-members {*/
  /*padding: 8rem 0;*/
  /*overflow-x: hidden;*/
/*}*/
/*.team-item.team-card,.team-card .card-header {*/
  /*border: 0 !important;*/
  /*border-radius: 0;*/
  /*height: 100%;*/
/*}*/
/*.leaders ul{*/
  /*display: flex;*/
  /*width: 100%;*/
  /*overflow-x: scroll;*/
/*}*/
/*.leaders ul li{*/
  /*display:inline-grid;*/
  /*max-width: 300px;*/
  /*flex-shrink: 0;*/
  /*margin-right: 10px;*/
  /*width: 300px;*/
  /*padding-bottom: 5rem;*/
/*}*/
/*.leaders ul li:first-child{*/
  /*margin-left: 10%*/
/*}*/
/*.leaders ul li:last-child{*/
  /*margin-right: 10%*/
/*}*/
/*.team-card p{*/
  /*font-size: 16px;*/
  /*font-weight: 300;*/
/*}*/
/*!* .team-image {*/
  /*height: 270px;*/
/*} *!*/
/*.team-card .card-body img{*/
  /*!* vertical-align: bottom ;*/
  /*height: 100%;*/
    /*object-fit: cover; *!*/
    /*!* position: absolute;*/
    /*bottom: 0; *!*/
/*}*/
/*.team-card .card-header{margin-bottom: -1px;}*/
/*.team-card .card-header h6{font-size: 3rem;}*/
/*.leaders ul::-webkit-scrollbar {*/
  /*background: var(--white-color);*/
  /*border-top: 5px solid var(--black-color);*/
  /*border-bottom: 4px solid var(--black-color);*/
 /**/
/*}*/

/*.leaders ul::-webkit-scrollbar-thumb {*/
  /*background-color: var(--primary-color);*/
  /*border-radius: 10px;*/
  /*height: 5px;*/
/*}*/
/* media center */
.pr-media{padding: 8rem 0 0;}
.brand-item{
  background:#081520;
  height: 200px;
  position: relative;
}
.brand-item .card-overlay{
  padding:8px;
}
.brand-item .card-overlay-inner p,.pr-content .card-overlay-inner p{
  color: var(--white-color);
  font-weight: 400;
  text-transform: none;
  font-size: 12px;
  margin-bottom: 0;
}
.brand-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  width: 60%;
}
.pr-item img {
  height: 215px;
  object-fit: cover;
}
.pr-item:hover a{
  color: var(--primary-color);
}

/* case-study/our works */
.case-study-listing,.home-work {
  padding: 8rem 0;
}
.stream-heroes p {
  font-size: 15px;
  font-weight: 800;
}

.casystudy-blocks h6{
  font-family: 'Avenir LT Std';
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 1rem;
  color: var(--primary-color);
}
.casystudy-blocks p{
  font-size: 16px;
  margin-bottom: 0;
}
.case-detail-stream{
  padding: 5rem 0 6rem;
}
/* Casestudy ends */

/* privacy-policy */
.privacy{
  padding: 10rem 0 6rem;
}
.text-vertical-scroll{
  padding: 0rem 3rem;
  overflow-y: scroll;
  height: 600px;
}
.cute_scroll::-webkit-scrollbar{
  width: 10px;
  background-color: var(--black-color)  ;
}
.cute_scroll::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: var(--white-color)  ;
  border-left: 4px solid var(--black-color);
  border-right: 4px solid var(--black-color);
}
.cute_scroll::-webkit-scrollbar-thumb{
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 px #ad1457 ;
  background-color: var(--primary-color)   ;
}

/* Terms Conditions */
.terms-banner{padding: 8rem 0;}
/* Lead Generation  */
.lead-generation-banner{
  /* background: url(../images/lead-generation-banner.jpg); */
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}
.lead-generation-form h6{
  font-size:2.6rem;
  line-height:3rem;
  margin-bottom:2rem
}
.lead-generation-brands{
  height: 100vh;
}
.lead-generation-brands h6{
  font-size:2.4rem;
  line-height:2.4rem;
}

/* career page starts */
.career{
  /* height: 100vh; */
  margin: 5rem 0 5rem;
}
/* coming-soon */
.coming-soon,.page-error{
  height: 100vh;
}

/* landing page */
.landing-page-block{padding: 0rem 0;background-size: 100%;}
.landing-brand{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2rem  0;
}
.landing-page-form .form-control{
  background: #35626fd4;
  padding: 0.6rem 1rem;
}
.landing-page-form .submit-button{
  background: var(--primary-color); color:#000000;
  border: 0;
  font-weight: 900;
  padding: 0.5rem 3rem;
}
.landing-page-form .form-group label{font-size: 16px;}
.landing-brand ul {
  display: inline-block;
}
.landing-brand ul li {
  list-style-type: none;
  display: inline-block;
}
.landing-brand ul li img{width: 80px;}

@media(max-width:767px){
  .coming-soon,.page-error{
    height: 100%;
    padding: 12rem 0 8rem;
  }
}


@media(max-width:767px){
  .career{
    height: 100%;
    margin: 8rem 0 3rem;
  }
}
/* Career page ends */









/* media query */
@media(min-width:768px){
  .show-responsive{display: none !important;}
  /* vectors */
  .vector{
    width: 15%;
    height: 15px;
    position: absolute;
    z-index: 0;
    left: 0;
    right:0;
  }
  .left-bottom-vector{
    bottom: 0;
  }
  .right-bottom-vector{
    right: 0;
    left: unset;
    bottom: 0;
    width: 40%;
    height: 10px;
  }
  .right-bottom-short-vector{
    right: 0;
    left: unset;
    bottom: 0;
    width: 10%;
    height: 30px;
  }
  .left-top-vector{
    top: 0;
    left: 15%;
  }
  .right-top-vector{
    right: 0;
    left: unset;
    top: 0;
    width: 30%;
  }
  .center-bottom-vector{
    bottom: 0;
    margin: auto;
    width: 30%;
  }
  .plg-brand .right-bottom-vector,.studios .right-bottom-vector,.right-center-vector, .terms-banner .right-bottom-vector,.education-content .right-bottom-vector,.team-members .right-bottom-vector{
    height: 15px;
    width: 25%;
    right: 25%;
  }
  .studios .left-top-vector {
    height: 15px;
    width: 25%;
    left: 30%;
    height: 15px;
  }
  .right-top-short-vector{
    top: 0;
    left: unset;
    right: 0;
    height: 15px;
    width: 10%;
  }
  .home-contact .left-top-vector{
    left: 15%;
    width: 25%;
  }

  .layer {
    width: 20%;
    height: 10px;
    background: var(--primary-color);
    position: absolute;
    z-index: 99;
    bottom: 0;
  }
  .layer1{
    width: 15%;
  }
  .layer2 {
    left: 25%;
    width: 25%;
    bottom: -10px;
  }
  .layer3 {
    left: 55%;
    width: 30%;
  }
  .layer4{
    right: 0;
    bottom: -10px;
    width: 10%;
  }
  .layer5{
    right: 0;
    height: 5px;
  }
  /* .screen-layout{
    padding: 3rem 0;
  } */
  /* services */
  .responsive-modal.show,.modal-backdrop.show{
    display: none !important;
  }
}

/* mobile */
@media(max-width:767px){
  body{font-size: 18px;}
  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  h2 {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  .show-desktop{
    display: none !important;
  }
  h3{
    font-size: 3rem;
    line-height:3rem;
  }
  h4{
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  h5{
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
  h6{
    font-size: 2rem;
    line-height: 2.5rem;
  }
  /* footer */
  footer{
    padding: 3rem 0 1rem;
  }
  footer ul li{
    letter-spacing: 1px;
    padding: 0.45rem;
    font-size:12px;
    margin: 0;
  }
  .social-media li a {
    width: 24px;
    height: 24px;
    margin: 0 0rem;
  }
  footer p{margin: 1rem 0 0.5rem;}
  .scroll-down{bottom: 1rem;}
  .scroll-down .scroll-down-text{font-size: 12px;}
  .scroll-down .scroll-down-text i {
    font-size: 1.2rem;
}
  /* video */
  .video-section,.video-section video,.plg-brand,.home-contact{
    height:100%;
  }
  .video-section{
    padding: 8rem 0;
  }

  /* connect */
  .home-contact{padding: 3rem 0;}
  .form-control{
    background: transparent;
    border: 1px solid var(--white-color);
    color: var(--white-color) !important;
  }
  .form-control::placeholder{color: var(--white-color) !important;}
  .form-control:focus{
    background-color: transparent;
  }
  .connect-tabs form {
    margin: 20px 10px !important;
  }
  .connect-tabs ul li button{
    font-size: 16px;
    padding: 0 0.5rem;
    margin: 0;
  }
  /* services */
  .services{
    padding: 5rem 0;
  }
  .responsive-modal .modal-dialog{
    margin: 0;
  }
  .responsive-modal .modal-content{
    border-radius: 0;
    border:0;
    background-color: var(--primary-color);
  }
  /*.responsive-modal  .modal-dialog,.responsive-modal .modal-content{height: 100%;}*/
  .responsive-modal .modal-header {
    background: var(--black-color);
    border-radius: 0;
    padding: 7rem 3rem 3rem;
  }
  .responsive-modal .btn-close {
    background: none;
    font-size: 4rem;
    opacity: 1;
    transform: rotate(45deg);
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 800;
  }
  .responsive-modal .modal-body{
    padding: 5rem 2rem 3rem;
  }
  .responsive-modal .modal-body p {
    font-size: 20px;
    color: var(--black-color);
    line-height: 37px;
  }
  .responsive-modal .left-top-vector{
    width: 15%;
    height: 15px;
    position: absolute;
    z-index: 999;
    left: 0;
    right:0;
    background: var(--primary-color);
  }
  .responsive-modal .right-top-vector{
    width: 40%;
    height: 15px;
    position: absolute;
    z-index: 999;
    right:0;
    background: var(--primary-color);
  }
  .services-blocks:hover .services-content{
    opacity: 0;
  }
  /* studios screen */
  .page-banner{margin: 0 0 3rem;height: 75vh;}
  .screen-layout p {
    font-size: 16px;
    line-height: 25px;
  }
  .screen-layout{padding: 2rem 15px;}

  /* media brand */
  .brand-item.work-item .card-overlay{
    display: none;
  }
  .brand-item{
    height: 120px;
  }
  .brand-item img{width: 45%;}
  .pr-media {
    padding: 3rem 0 0;
  }
  /* lead-generation */
  .lead-generation-banner {
    height: 100%;
  }
  .lead-generation-banner .banner-text {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
  .lead-generation-brands img{
    width: 80%;
  }
  .lead-generation-brands h6{
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  /* privacy policy */
  .privacy ,.terms-banner{
    padding: 7rem 0 0rem;
  }
  .text-vertical-scroll{
    height: 100%;
    padding: 2rem 0;
  }
  .privacy .cute_scroll::-webkit-scrollbar{display: none;}

  .team-members,.leaders ul li{padding-bottom: 2rem;}
  .our-works{
    padding: 8rem 0 0;
  }

}
@media(max-width:350px){
  .connect-tabs ul li button{
    font-size: 14px;
  }


}
@media (max-width:480px)  { 
  .sticky-items .theam-button {
    font-size: 14px;
    padding: 0.8rem 0.5rem 0.6rem;
    letter-spacing: 2px;
}
  /* .stickey-button {
    -webkit-transform-origin-x: 0;
    bottom: 16%;
    left: 0;
    position: fixed;
    -webkit-transform: scale(.7);
    transform: scale(0.5);
    z-index: 8;
} */
 }

/*button{*/
/*cursor: url(../../curser-pointer/Contact-us.png)10 20, auto;*/
/*transition: all 1s ease;*/
/*}*/
/*.logoCurser {*/
/*cursor: url(../../curser-pointer/Normal-pointer.png), auto;*/
/*transition: all 1s ease;*/

/*}*/
/*.videoPoniter {*/
/*cursor: url(../../curser-pointer/Video.png), auto;*/
/*transition: all 1s ease;*/

/*}*/
/*.messagePointer,input,.tab-content,.connect-tabs,textarea{*/
/*cursor: url(../../curser-pointer/Contact-us.png)15 15, auto;*/
/*transition: all 1s ease;*/

/*}*/
.LandingLead{
  margin-top: 9rem !important;
}
.LandingLead .form-control {
  font-size: 16px;
  letter-spacing: 2px;
  border: 0;
  padding: 0.3rem 1rem;
  border-radius: 0;
  background-color: rgb(1 91 107 / 55%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: #fff;
}
.LandingLead label{
  font-size: 17px !important;
}
.landing-lines{
  position: absolute;
  width: 100%;
  z-index: 1;
}
@media (max-width:900px)  { 
  .LandingLead{
    margin-top: 4rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

  }
}
@media (min-width:300px) and (max-width:600px)  { 
  .landing-lines{
    margin-top: -15px;
  }
}
@media (min-width:600px) and (max-width:900px){ 
  .landing-lines{
    margin-top: -22px;
  }
}
@media (min-width:900px) and (max-width:1200px){ 
  .landing-lines{
    margin-top: -30px;
  }
}
@media (min-width:1200px) and (max-width:1500px){ 
  .landing-lines{
    margin-top: -37px;
  }
}
@media (min-width:1500px) and (max-width:1800px){ 
  .landing-lines{
    margin-top: -45px;
  }
}
@media (min-width:1800px) and (max-width:2100px){ 
  .landing-lines{
    margin-top: -58px;
  }
}
@media (min-width:2100px) and (max-width:3000px){ 
  .landing-lines{
    margin-top: -50px;
  }
}