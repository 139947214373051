.banner-text {
    overflow: hidden;
}

.main-banner {
    height: 100vh;
    z-index: 1;
}

.page-banner .banner-text,
.home-banner .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90%;
}

.backgrondImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.banner-height{
    min-height: 650px;
}
/* .phonix-banner-content{
   padding-top: 10rem;
   padding-bottom: 10rem;
} */
.phoniex-banner {
    margin: 0rem 0 0rem !important;
}
.phonix-banner-image{
    width: 100%;
    height: auto;
}
/* @media (min-width: 600px) { 
    .phonix-banner-image{
        width: 100%;
        height: 120vh;
    }
 }
@media (min-width: 900px) { 
    .phonix-banner-image{
        width: 100%;
        height: 135vh;
    }
 }
@media (min-width: 1200px) { 
    .phonix-banner-image{
        width: 100%;
        height: 150vh;
    }
 }
 */
 .phoniex-banner{
    background-size: cover;
   background-position: left;
   background-repeat: no-repeat;
   width: 100%;
   height: auto;
 }
 .phoniex-banner-form{
    background-size: cover;
   background-position: left;
   background-repeat: no-repeat;
   width: 100%;
   height: auto;
 }
 .phoniex-banner-form .container{
    padding-top: 4rem;
    padding-bottom: 3rem;
 }
 .phoniex-banner-form p{
    font-size: 18px;
 }
 .phoniex-banner-padding{
    padding-top: 8rem;
    padding-bottom: 3rem;
 }
 .brand-bg-padding{
    padding-top: 2rem;
    padding-bottom: 3rem;
 }
